<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>ユーザー作成</template>
              <template v-slot:body>
                <div class="form">
                  <section class="form-section">
                    <FormRow :required="true">
                      <template v-slot:label>ユーザー名</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="form-group">
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameSei }"
                                type="text"
                                name="nameSei"
                                placeholder="姓"
                                v-trim
                                v-maxlength
                                v-space
                                maxlength="20"
                                v-model="form.nameSei"
                              />
                            </div>
                            <div class="form-group-item">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.nameMei }"
                                type="text"
                                name="nameMei"
                                placeholder="名"
                                v-trim
                                v-maxlength
                                v-space
                                maxlength="20"
                                v-model="form.nameMei"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>ID</template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-5">
                              <input
                                :class="{ 'form-control': true, 'is-error': errors.cpmUserId }"
                                type="text"
                                name="cpmUserId"
                                v-trim
                                v-maxlength
                                maxlength="20"
                                v-model="form.cpmUserId"
                              />
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                    <FormRow :required="true">
                      <template v-slot:label>初回パスワード</template>
                      <template v-slot:subLabel><span class="text-note">10文字以上の半角英数字および記号（/ \ ¥ を除く）すべての組み合わせ</span></template>
                      <template v-slot:content>
                        <div class="form-content-row">
                          <div class="row">
                            <div class="col-5">
                              <div class="form-password">
                                <input
                                  v-trim
                                  v-model="form.password"
                                  :class="{ 'form-control form-password-input': true, 'is-error': errors.password }"
                                  :type="pwdVisible ? 'text' : 'password'"
                                  name="password"
                                />
                                <label class="form-check form-password-toggle">
                                  <input class="form-check-input" type="checkbox" v-model="pwdVisible" />
                                  <i class="aikon form-password-icon"></i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </FormRow>
                  </section>
                </div>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'AccountUserCpm' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="register" button-text="登録する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
//mixins
import cpmUser from '@/mixins/module/cpmUser';
//helpers
import { isAlphanumSpecialWithMinLength, isAlphanumWithMaxLength, notSpace } from '@/helpers/validators';
//component
import FormRow from '@/components/FormRow.vue';

export default {
  name: 'AccountUserCpmRegister',
  data: function() {
    return {
      pageName: 'アカウント設定',
      form: {
        nameSei: '',
        nameMei: '',
        cpmUserId: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      userAccount: 'account/userAccount',
      shopList: 'shop/shopList',
    })
  },
  components: {
    FormRow,
  },
  validations() {
    const form = {
      nameSei: { required, maxLength: maxLength(20), notSpace },
      nameMei: { required, maxLength: maxLength(20), notSpace },
      cpmUserId: { required, alphanumWithMaxLength: isAlphanumWithMaxLength(20) },
      password: { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(10) },
    }
    return { form };
  },
  mixins: [cpmUser],
  methods: {
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'inforCpm');
      } else {
        this.errors = {};
        const result = await this.dispatchAction('settingUserCpm/createCpmUser', { data: this.form });
        if (result) {
          await this.$router.push({ name: 'AccountUserCpm' });
          this.$message.showSuccess('createdUserSuccess');
        }
      }
    }
  }
};
</script>
